const products = [
  {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "1",
      "Name": "Minestronesuppe",
      "Price": "10.50",
      "Priceh":""

    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "2",
      "Name": "Tomatencremesuppe",
      "Price": "10.50",
      "Priceh":""

    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "3",
      "Name": "Bouillon min Tortellini",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "4",
      "Name": "Nusslisalata mit Ei",
      "Price": "10.50",
      "Priceh":""

    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "5",
      "Name": "Gruner Salat",
      "Price": "8.50",
      "Priceh":""

    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "6",
      "Name": "Gemischter Salat",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "7",
      "Name": "Grosser Salatteller",
      "Price": "17.50",
      "Priceh":""

    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "8",
      "Name": "Ruccola & Parmesan",
      "Price": "11.80",
      "Priceh":""

    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "9",
      "Name": "Tomaten & Mozzarelle Galbani",
      "Price": "14.00",
      "Priceh": "10.50 "
    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "10",
      "Name": "Griechischer Salat",
      "Price": "16.00",
      "Priceh": "11.50 "
    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "11",
      "Name": "Carpaccio al Basiliko",
      "Descriptione": "Rindfleisch  ,  Kapern  ,  Zwiebeln  ,  Parmesan  ,  Basilikum",
      "Price": "25.50",
      "Priceh": "21.00 "
    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "12",
      "Name": "Rucola mit frischem Lachs an Champagnersauce",
      "Price": "21.50",
      "Priceh": "16.50 "
    },
    {
      "categoryid": "1",
      "Category": "PRIMI PIATTA",
      "id": "13",
      "Name": "Antipasto Mista \"ITALIA2000\"",
      "Price": "24.50",
      "Priceh": "17.50 "
    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "14",
      "Name": "Margherita",
      "Descriptione": "Tomaten  ,  Mozzarella  ,   Oregano",
      "Price": "14.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "15",
      "Name": "Prosciutto",
      "Descriptione": "Tomaten  ,  Mozzarella  ,  Schinken  ,   Oregano",
      "Price": "18.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "16",
      "Name": "Napoli",
      "Descriptione": "Tomaten , Mozzarella , Sardelle , Kapern , Oregano",
      "Price": "20.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "17",
      "Name": "Alla Diavola",
      "Descriptione": "Tomaten , Mozzarella , Zwiebeln , Knoblauch , Peperoncini , Oregano",
      "Price": "18.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "18",
      "Name": "Marinara",
      "Descriptione": "Tomaten , Mozzarella , Meersesfruchte , Oregano",
      "Price": "21.00",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "19",
      "Name": "Traviata",
      "Descriptione": "Tomaten , Mozzarella , Artischocken , Oliven , Sardellen , Oregano",
      "Price": "21.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "20",
      "Name": "Siciliana",
      "Descriptione": "Tomaten , Mozzarella , Schinken , Pilze , Eier , Oregano",
      "Price": "22.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "21",
      "Name": "Quattro Stagioni",
      "Descriptione": "Tomaten ,  Mozzarella ,  Eier ,  Schinken ,  Pilze ,  Meeresfruchte ,  Oregano",
      "Price": "22.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "22",
      "Name": "Calzone",
      "Descriptione": "Tomaten ,  Mozzarella ,  Pilze ,  Schinken ,  Eier ,  Oregano",
      "Price": "22.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "23",
      "Name": "Romana",
      "Descriptione": "Tomaten ,  Mozzarella ,  Salami ,  peperoni ,  Oliven ,  Eier ,  Oregano",
      "Price": "21.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "24",
      "Name": "Pizza Italia 2000",
      "Descriptione": "Tomaten ,  Mozzarella ,  Kalbfleisch ,  Knoblauch ,  Zwiebeln ,  Oregano",
      "Price": "24.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "25",
      "Name": "Boscaiola",
      "Descriptione": "Tomaten ,  Mozzarella ,  Steinpilze ,  Speck ,  Knoblauch ,  Oregano",
      "Price": "23.00",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "26",
      "Name": "Gorgonzola",
      "Descriptione": "Tomaten ,  Mozzarella ,  Gorgonzola  ,  Oregano",
      "Price": "20.00",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "27",
      "Name": "Vegetariana",
      "Descriptione": "Tomaten ,  Mozzarella ,  Gemuse ,  Oregano",
      "Price": "20.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "28",
      "Name": "Calabrese",
      "Descriptione": "Tomaten ,  Mozzarella ,  pikante Salami ,  Oregano",
      "Price": "19.00",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "29",
      "Name": "Cipolla ",
      "Descriptione": "Tomaten ,  Mozzarella ,  Zwiebeln Oregano",
      "Price": "16.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "30",
      "Name": "Aglio",
      "Descriptione": "Tomaten ,  Mozzarella ,  Knoblauch ,  Oregano",
      "Price": "16.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "31",
      "Name": "Basilico",
      "Descriptione": "Tomaten ,  Mozzarella ,  Basilikum ,  Oregano",
      "Price": "16.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "32",
      "Name": "Salami",
      "Descriptione": "Tomaten ,  Mozzarella ,  Salami ,  Oregano",
      "Price": "18.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "33",
      "Name": "Prosciutto e Funghi",
      "Descriptione": "Tomaten ,  Mozzarella ,  Schinken ,  Pilze ,  Oregano",
      "Price": "21.00",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "34",
      "Name": "Florentina",
      "Descriptione": "Tomaten ,  Mozzarella ,  Spinat ,  Oliven ,  Knoblauch ,  Oregano",
      "Price": "21.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "35",
      "Name": "Capriccosa",
      "Descriptione": "Tomaten ,  Mozzarella ,  Schinken ,  Pilze ,  Oliven ,  Speck ,  Oregano",
      "Price": "22.00",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "36",
      "Name": "Tonno ",
      "Descriptione": "Tomaten ,  Mozzarella ,  Thunfisch ,  Zwiebeln ,  Oliven ,  Oregano",
      "Price": "20.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "37",
      "Name": "Hawaii",
      "Descriptione": "Tomaten ,  Mozzarella ,  Ananas ,  Schinken ,  Oregano",
      "Price": "20.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "38",
      "Name": "Melanzana",
      "Descriptione": "Tomaten ,  Mozzarella ,  Aubergine ,  Oliven ,  Knoblauch ,  Oregano",
      "Price": "21.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "39",
      "Name": "Porcini",
      "Descriptione": "Tomaten ,  Mozzarella ,  Steinpilze ,  Knoblauch ,  Oregano",
      "Price": "22.00",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "40",
      "Name": "Cleopatra",
      "Descriptione": "Tomaten ,  Mozzarella ,  Spinat ,  Kalbsneisch ,  Knoblauch ,  Zwiebeln ,  Oregano",
      "Price": "24.00",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "41",
      "Name": "India",
      "Descriptione": "Tomaten , Mozzarella , Poulet , Ananas , Curry , Oregano",
      "Price": "21.50",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "42",
      "Name": "Prosciutto Crudo",
      "Descriptione": "Tomaten , Mozzarella , Rohschinken , Oregano",
      "Price": "23.00",
      "Priceh":""

    },
    {
      "categoryid": "5",
      "Category": "PIZZE",
      "id": "43",
      "Name": "Carpaccio al Basiliko",
      "Descriptione": "Tomaten , Mozzarella , Carpaccio , Oregano",
      "Price": "23.00",
      "Priceh":""

    },
    {
      "categoryid": "6",
      "Category": "SPECIALITA DI CARNE",
      "id": "44",
      "Name": "Medaglione di Vitello",
      "Descriptione": "Kalbsmedaillon an Gorgonzolasauc",
      "Price": "49.00",
      "Priceh":""

    },
    {
      "categoryid": "6",
      "Category": "SPECIALITA DI CARNE",
      "id": "45",
      "Name": "Filetto di Maiale",
      "Descriptione": "Schweinsfilet an Steinpilzrahmsauce",
      "Price": "38.00",
      "Priceh":""

    },
    {
      "categoryid": "6",
      "Category": "SPECIALITA DI CARNE",
      "id": "46",
      "Name": "Filetto di Vitello con Funghi Porcini",
      "Descriptione": "Kalbsflet an Steinpilzrahmsauce",
      "Price": "49.00",
      "Priceh":""

    },
    {
      "categoryid": "6",
      "Category": "SPECIALITA DI CARNE",
      "id": "47",
      "Name": "Fegato di Vitello alla Veneziana",
      "Descriptione": "Kalbsleber in Sutter & Salbei",
      "Price": "35.00",
      "Priceh":""

    },
    {
      "categoryid": "6",
      "Category": "SPECIALITA DI CARNE",
      "id": "48",
      "Name": "Involtini Gorgonzola",
      "Descriptione": "Kalbswickel an Gorgonzolasauce",
      "Price": "38.00",
      "Priceh":""

    },
    {
      "categoryid": "6",
      "Category": "SPECIALITA DI CARNE",
      "id": "49",
      "Name": "Finissima di Vitello",
      "Descriptione": "Kalbsfillet vom Grill",
      "Price": "49.00",
      "Priceh":""

    },
    {
      "categoryid": "6",
      "Category": "SPECIALITA DI CARNE",
      "id": "50",
      "Name": "Cotoletta di Vitello ai Ferri",
      "Descriptione": "Kalbskotelett vom Grill",
      "Price": "37.00",
      "Priceh":""

    },
    {
      "categoryid": "6",
      "Category": "SPECIALITA DI CARNE",
      "id": "51",
      "Name": "Fegato di Vitello ai Ferri",
      "Descriptione": "Kalbsleber vom Grill",
      "Price": "35.00",
      "Priceh":""

    },
    {
      "categoryid": "2",
      "Category": "SECONDI PIATTI",
      "id": "52",
      "Name": "Filetto di Manzo alla Rucola",
      "Descriptione": "US-Rindsfillet auf Rucolasalat (220g)  Reis    /  Polenta    /  Fettucine    /  Patate    /  Gemuse    /  Risotto alla Parmigiana",
      "Price": "49.00",
      "Priceh":""

    },
    {
      "categoryid": "2",
      "Category": "SECONDI PIATTI",
      "id": "53",
      "Name": "Saltinbocca alla Romana",
      "Descriptione": "CH-Kalbsschnitzel mit Parmaschinken & Salbei  Reis    /  Polenta    /  Fettucine    /  Patate    /  Gemuse    /  Risotto alla Parmigiana4",
      "Price": "39.00",
      "Priceh":""

    },
    {
      "categoryid": "2",
      "Category": "SECONDI PIATTI",
      "id": "54",
      "Name": "Scaloppine al Limone",
      "Descriptione": "CH-Kalbsschnitzel an Zitronensauce  Reis    /  Polenta    /  Fettucine    /  Patate    /  Gemuse    /  Risotto alla Parmigiana",
      "Price": "38.00",
      "Priceh":""

    },
    {
      "categoryid": "2",
      "Category": "SECONDI PIATTI",
      "id": "55",
      "Name": "Scaloppine alla Pizzaiola",
      "Descriptione": "Kalbsschnitzel Pizzaiola  Reis  / Polenta  / Fettucine  / Patate  / Gemuse  / Risotto alla Parmigiana",
      "Price": "38.00",
      "Priceh":""

    },
    {
      "categoryid": "2",
      "Category": "SECONDI PIATTI",
      "id": "56",
      "Name": "Scaloppine alla Panna",
      "Descriptione": "Kalbsschnitzel mit Rahm  Reis  / Polenta  / Fettucine  / Patate  / Gemuse  / Risotto alla Parmigiana",
      "Price": "38.00",
      "Priceh":""

    },
    {
      "categoryid": "2",
      "Category": "SECONDI PIATTI",
      "id": "57",
      "Name": "Picata Milanese",
      "Descriptione": "Kalbsfleisch  Reis  / Polenta  / Fettucine  / Patate  / Gemuse  / Risotto alla Parmigiana",
      "Price": "38.00",
      "Priceh":""

    },
    {
      "categoryid": "7",
      "Category": "SPECIALITA DI PESCE",
      "id": "58",
      "Name": "Filetto di Sogliola al Vino Bianco",
      "Descriptione": "Seezungenflet an Weissweinsauce",
      "Price": "42.00",
      "Priceh":""

    },
    {
      "categoryid": "7",
      "Category": "SPECIALITA DI PESCE",
      "id": "59",
      "Name": "Calamari Fritti",
      "Descriptione": "Tintenfisch paniert im Bierteig mit Tartarsauce",
      "Price": "25.50",
      "Priceh":""

    },
    {
      "categoryid": "7",
      "Category": "SPECIALITA DI PESCE",
      "id": "60",
      "Name": "Gamberoni alla Guazzetto",
      "Descriptione": "Riesencrevetten mit frischen Tomaten & Knoblauch",
      "Price": "45.50",
      "Priceh":""

    },
    {
      "categoryid": "7",
      "Category": "SPECIALITA DI PESCE",
      "id": "61",
      "Name": "Eglifilet Muller-Art",
      "Descriptione": "Eglifilet an Weissweinsauce mit Krautern",
      "Price": "37.50",
      "Priceh":""

    },
    {
      "categoryid": "7",
      "Category": "SPECIALITA DI PESCE",
      "id": "62",
      "Name": "Grigliata mista",
      "Price": "49.00",
      "Priceh":""

    },
    {
      "categoryid": "7",
      "Category": "SPECIALITA DI PESCE",
      "id": "63",
      "Name": "Sogliola",
      "Price": "42.00",
      "Priceh":""

    },
    {
      "categoryid": "7",
      "Category": "SPECIALITA DI PESCE",
      "id": "64",
      "Name": "Gamberoni",
      "Price": "42.00",
      "Priceh":""

    },
    {
      "categoryid": "7",
      "Category": "SPECIALITA DI PESCE",
      "id": "65",
      "Name": "Bel Paese ,  Taleggio ,  Parmigiano ,  Gorgonzola",
      "Price": "19.50",
      "Priceh": "13.50 "
    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "66",
      "Name": "Tiramisu",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "67",
      "Name": "Pannacotta",
      "Price": "8.50",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "68",
      "Name": "Crema апа Caramello",
      "Price": "8.50",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "69",
      "Name": "Diverse Frapp6s",
      "Price": "8.50",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "70",
      "Name": "Cafe Crema",
      "Price": "4.40",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "71",
      "Name": "Espresso",
      "Price": "4.40",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "72",
      "Name": "Doppio Espresso",
      "Price": "5.50",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "73",
      "Name": "Cappucino",
      "Price": "5.50",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "74",
      "Name": "Latte Machiatt0",
      "Price": "6.50",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "75",
      "Name": "Cafe Corretto",
      "Price": "7.50",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "76",
      "Name": "Тее",
      "Price": "4.40",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "77",
      "Name": "Тее Rum",
      "Price": "7.50",
      "Priceh":""

    },
    {
      "categoryid": "8",
      "Category": "DESSERT",
      "id": "78",
      "Name": "kinder Sirup",
      "Price": "2.50",
      "Priceh":""

    },
    {
      "categoryid": "4",
      "Category": "PASTA ASCIUTTA E PASTA AL FORNO",
      "id": "79",
      "Name": "Penne Pomodoro",
      "Descriptione": "MitTomatensauce",
      "Price": "18.50",
      "Priceh": "14.50 "
    },
    {
      "categoryid": "4",
      "Category": "PASTA ASCIUTTA E PASTA AL FORNO",
      "id": "80",
      "Name": "Penne Arrabbiata",
      "Descriptione": "Mit Tomatensauce ,  Knobl auch & Peperoncini",
      "Price": "19.50",
      "Priceh": "15.50"
    },
    {
      "categoryid": "4",
      "Category": "PASTA ASCIUTTA E PASTA AL FORNO",
      "id": "81",
      "Name": "Penne al Champagner",
      "Descriptione": "Mit scharferTomatensciuce , Rahm&Champagner",
      "Price": "22.50",
      "Priceh": "16.50 "
    },
    {
      "categoryid": "4",
      "Category": "PASTA ASCIUTTA E PASTA AL FORNO",
      "id": "82",
      "Name": "Cappelletti gefullt mit Parmaschinken alla Panna ",
      "Price": "25.00",
      "Priceh":""

    },
    {
      "categoryid": "4",
      "Category": "PASTA ASCIUTTA E PASTA AL FORNO",
      "id": "83",
      "Name": "Ravioli gefullt mit Zucchett i alla Parmigiana ",
      "Price": "25.00",
      "Priceh":""

    },
    {
      "categoryid": "4",
      "Category": "PASTA ASCIUTTA E PASTA AL FORNO",
      "id": "84",
      "Name": "Ravioli gefu llt mit ArtischockenBurro e Parmigiana ",
      "Price": "25.00",
      "Priceh":""

    },
    {
      "categoryid": "4",
      "Category": "PASTA ASCIUTTA E PASTA AL FORNO",
      "id": "85",
      "Name": "Panzerotti gefullt mit Spinal & Ricotta",
      "Price": "25.00",
      "Priceh":""

    },
    {
      "categoryid": "4",
      "Category": "PASTA ASCIUTTA E PASTA AL FORNO",
      "id": "86",
      "Name": "Lasagna fate a Casa al Forno",
      "Descriptione": "Hausgemachte LasagneUberbacken",
      "Price": "22.50",
      "Priceh":""

    },
    {
      "categoryid": "4",
      "Category": "PASTA ASCIUTTA E PASTA AL FORNO",
      "id": "87",
      "Name": "Cannelloni al Forno fate a Casa al Forno",
      "Descriptione": "Hausgemacht mit FleischfUllung , Ricotta & Spinat",
      "Price": "22.50",
      "Priceh": "16.50 "
    },
    {
      "categoryid": "4",
      "Category": "PASTA ASCIUTTA E PASTA AL FORNO",
      "id": "88",
      "Name": "Maccheroni alla Romana",
      "Descriptione": "Mit Schinken ,  Erbsen , Pil.zen & Rahmsauce",
      "Price": "21.50",
      "Priceh": "16.50 "
    },
    {
      "categoryid": "3",
      "Category": "SPHAGETTI O TAGLIATELLE",
      "id": "89",
      "Name": "Spaghetti alla Napoletana",
      "Descriptione": "MilTomatensauce",
      "Price": "17.80",
      "Priceh": "13.80 "
    },
    {
      "categoryid": "3",
      "Category": "SPHAGETTI O TAGLIATELLE",
      "id": "90",
      "Name": "Spaghett i al Pesto",
      "Descriptione": "Mit Basilikumsauce",
      "Price": "20.80",
      "Priceh": "15.80 "
    },
    {
      "categoryid": "3",
      "Category": "SPHAGETTI O TAGLIATELLE",
      "id": "91",
      "Name": "Spaghett i Aglio & Olio",
      "Descriptione": "Mit Knoblauch , Oliven0I& Peperoncini",
      "Price": "19.80",
      "Priceh": "14.80 "
    },
    {
      "categoryid": "3",
      "Category": "SPHAGETTI O TAGLIATELLE",
      "id": "92",
      "Name": "Spaghetti alla Carbonara",
      "Descriptione": "Mit Schinken ,  Speck& Ei",
      "Price": "21.50",
      "Priceh": "16.50 "
    },
    {
      "categoryid": "3",
      "Category": "SPHAGETTI O TAGLIATELLE",
      "id": "93",
      "Name": "Spaghetti alla Bolognese",
      "Descriptione": "Mit Bolognesesauce",
      "Price": "15.50",
      "Priceh": "15.50 "
    },
    {
      "categoryid": "3",
      "Category": "SPHAGETTI O TAGLIATELLE",
      "id": "94",
      "Name": "Spaghetta ta (ab 2 Personen)",
      "Descriptione": "Spaghettiplausc h mit6 verschiedenen Saucen",
      "Price": "26.50",
      "Priceh":""

    },
    {
      "categoryid": "3",
      "Category": "SPHAGETTI O TAGLIATELLE",
      "id": "95",
      "Name": "Tricolore di Pasta Fresca (ab 2 Personen)",
      "Descriptione": "Teigwaren ,  3-farbig",
      "Price": "24.50",
      "Priceh":""

    },
    {
      "categoryid": "3",
      "Category": "SPHAGETTI O TAGLIATELLE",
      "id": "96",
      "Name": "Tagliatelle al Salmone",
      "Descriptione": "Hausgemacht mit Rahm s & La , chs",
      "Price": "24.50",
      "Priceh":""

    },
    {
      "categoryid": "3",
      "Category": "SPHAGETTI O TAGLIATELLE",
      "id": "97",
      "Name": "Gnocchi al Gorgonzola",
      "Descriptione": "Mit Gorgonzola",
      "Price": "21.50",
      "Priceh":""

    },
    {
      "categoryid": "3",
      "Category": "SPHAGETTI O TAGLIATELLE",
      "id": "98",
      "Name": "Risotto con Funghi Porcini",
      "Price": "29.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "id": "5500",
      "Name": "Minerale"
    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Calanda mit Kohlensäure",
      "Descriptione": "5dl",
      "Price": "5.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Calanda ohne Kohlensäure",
      "Descriptione": "5dl",
      "Price": "5.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Rivella Rot, Blau, Grün",
      "Descriptione": "3dI",
      "Price": "4.80",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Coco-Cola",
      "Descriptione": "3dl",
      "Price": "4.80",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Coco-Cola light",
      "Descriptione": "3dl",
      "Price": "4.80",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Coca-Cola zero",
      "Descriptione": "3dl",
      "Price": "4.80",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Sinalco",
      "Descriptione": "3dl",
      "Price": "4.80",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Fanta",
      "Descriptione": "3dl",
      "Price": "4.80",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Apfelsaft",
      "Descriptione": "3dl",
      "Price": "4.80",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Tomatensaft",
      "Descriptione": "3dl",
      "Price": "5.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Orangensaft",
      "Descriptione": "3dl",
      "Price": "5.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Traubensaft",
      "Descriptione": "2dl",
      "Price": "4.80",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Orangensaft",
      "Descriptione": "3dl",
      "Price": "5.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Schweppes Tonic",
      "Descriptione": "3dl",
      "Price": "4.80",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Schweppes Bitter Lemon",
      "Descriptione": "3dl",
      "Price": "4.80",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Eistee",
      "Descriptione": "3dl",
      "Price": "3.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Citron",
      "Descriptione": "3dl",
      "Price": "3.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "id": "5500",
      "Name": "BIRRA"
    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Herrgöttli",
      "Price": "3.80",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Stange Bier",
      "Price": "4.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Grosses Bier",
      "Price": "5.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Clausthaler",
      "Price": "5.00",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Erdinger Weissbier",
      "Price": "7.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Feldschlösschen",
      "Price": "6.00",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Heineken",
      "Price": "6.00",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "id": "5500",
      "Name": "GIN"
    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Gordon's Gin",
      "Descriptione": "4cl",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Gordon's Gin mit Schweppes Tonic",
      "Price": "13.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "id": "5500",
      "Name": "APERITIVO"
    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Martini Bianco",
      "Descriptione": "4cl",
      "Price": "8.00",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Campari",
      "Descriptione": "4cl",
      "Price": "8.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Campari Orange",
      "Descriptione": "4cl",
      "Price": "10.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Cynar",
      "Descriptione": "4cl",
      "Price": "8.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "id": "5500",
      "Name": "BRANDY"
    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Vecchia Ronnagna",
      "Descriptione": "2cl",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Calvados",
      "Descriptione": "2cl",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "id": "5500",
      "Name": "LIQUORI"
    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Sanbucca",
      "Descriptione": "2cl",
      "Price": "8.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Amaretto di Saronno",
      "Descriptione": "2cl",
      "Price": "8.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Grand Marnier",
      "Descriptione": " 2cl",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Ramazotti ",
      "Descriptione": "4cl",
      "Price": "8.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Amaro Averna",
      "Descriptione": " 4cl",
      "Price": "8.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Limoncello",
      "Descriptione": "4cl",
      "Price": "8.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "id": "5500",
      "Name": "GRAPPA"
    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Grappa di Brunello",
      "Descriptione": "2cl",
      "Price": "12.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Grappa Nardini",
      "Descriptione": "2cl",
      "Price": "12.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Grappa Bocchino Riserva 12 Anni ",
      "Descriptione": "2cl",
      "Price": "10.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Grappa di Chardonnay",
      "Descriptione": "2cl",
      "Price": "9.00",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Grappa Paesanella",
      "Descriptione": "2cl",
      "Price": "9.00",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Williams",
      "Descriptione": "2cl",
      "Price": "9.00",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Kirsch",
      "Descriptione": "2cl",
      "Price": "9.00",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "id": "5500",
      "Name": "WHISKY & VODKA"
    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Chivas Regal",
      "Descriptione": "4cl ",
      "Price": "12.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Jack Daniels",
      "Descriptione": "4cl ",
      "Price": "12.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Ballantines ",
      "Descriptione": "4cl ",
      "Price": "10.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Black Label",
      "Descriptione": "4cl ",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Alle Whisky mit 33c1 Cola",
      "Price": "13.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Vodka Weiss",
      "Descriptione": "4cl",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Vodka Rot",
      "Descriptione": "4cl",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Vodka Blau",
      "Descriptione": "4cl",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Vodka Schwarz",
      "Descriptione": "4cl",
      "Price": "9.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Alle Vodka mit RedBull oder Schweppes",
      "Price": "13.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "id": "5500",
      "Name": "COGNAC"
    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Remy Martin ",
      "Descriptione": "2cl ",
      "Price": "12.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Hennesy",
      "Descriptione": "2cl ",
      "Price": "10.50",
      "Priceh":""

    },
    {
      "categoryid": "10",
      "Category": "DRINK",
      "Name": "Corvosier",
      "Descriptione": "2cl ",
      "Price": "12.50",
      "Priceh":""

    },
  {
    categoryid: "9",
    Category: "VINO",
    id: "5500",
    Name: "PIEMONTE ROSSI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "100",
    Name: "Barolo Quasso Conelli",
    Viti: "2007",
    Descriptione: "Nebbiolentrauben",
    Price: "69",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "101",
    Name: "Barbere d' Alba Superiore",
    Viti: "2009",
    Descriptione: "Barique Barolotrauben",
    Price: "59",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "102",
    Name: "Barbere d' Asti Araldica",
    Viti: "2009",
    Descriptione: "Ceppi Storici",
    Price: "53",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "103",
    Name: "Barbera Piemonte",
    Viti: "2008",
    Descriptione: "Borgovecchio",
    Price: "49",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "5500",
    Name: "VENETO ROSSI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "104",
    Name: "Amarone della Valpolicella",
    Viti: "2007",
    Descriptione: "Brigaldara San Floriano",
    Price: "110",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "105",
    Name: "Amarone della Valpolicella",
    Viti: "2008",
    Descriptione: "Rocca Alata",
    Price: "73",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "106",
    Name: "Zeni della Valpolicella",
    Viti: "2009",
    Descriptione: "Classico Superiore",
    Price: "52",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "107",
    Name: "Zeni della Bardolino",
    Viti: "2009",
    Descriptione: "Bardolino Classico",
    Price: "49",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "5500",
    Name: "TOSCANA ROSSI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "108",
    Name: "Chianti Donatello",
    Viti: "2009/10",
    Descriptione: "Antonini",
    Price: "49",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "109",
    Name: "Brunello di Montalcino",
    Viti: "2004",
    Descriptione: "Baroncini",
    Price: "89",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "110",
    Name: "Friularo Sansovino",
    Viti: "2005",
    Descriptione: "Ambasciatore",
    Price: "81",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "5500",
    Name: "EMILIA ROSSI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "111",
    Name: "Lambrusco",
    Viti: "2004",
    Descriptione: "Vino frizzante Emiliana",
    Price: "49",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "112",
    Name: "Merlot Fagiani",
    Viti: "2010",
    Descriptione: "Antonini Emiliana",
    Price: "39",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "5500",
    Name: "PUGLIA ROSSI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "113",
    Name: "Primitivo di Salento",
    Viti: "2009",
    Descriptione: "Vecchia Torro",
    Price: "55",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "114",
    Name: "Salice Salentino",
    Viti: "2009",
    Descriptione: "Negroamaro",
    Price: "49",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "115",
    Name: "Montepulciano d' Abruzzo",
    Viti: "2010",
    Descriptione: "Antonini",
    Price: "39",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "5500",
    Name: "SICILIA ROSSI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "116",
    Name: "Gorgo Tonda",
    Viti: "2008",
    Descriptione: "Duca di Castelemonte",
    Price: "60",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "5500",
    Name: "UMBRIA BIANCHI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "117",
    Name: "Orivieto Classico",
    Viti: "2008",
    Descriptione: "Cardeto Toscana",
    Price: "49",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "118",
    Name: "Chardonnay Canti",
    Viti: "2006",
    Descriptione: "Pinot Grigio",
    Price: "52",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "119",
    Name: "Chardonnay L ' Oriol",
    Viti: "2008",
    Descriptione: "Vin de France",
    Price: "49",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "120",
    Name: "MARCHE BIANCHI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "121",
    Name: "Frascati Superiore",
    Viti: "2008",
    Descriptione: "Cluffa Style",
    Price: "55",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "5500",
    Name: "SICILIA BIANCHI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "122",
    Name: "Fior di Settembre",
    Viti: "2008",
    Descriptione: "Antonini Sicilia",
    Price: "43",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "123",
    Name: "Zeni Soave",
    Viti: "2008",
    Descriptione: "Bardolino",
    Price: "46",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "5500",
    Name: "VINI ROSSI APERTI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "124",
    Name: "Primitivo di Salento",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "6",
    dl2: "12",
    dl3: "18",
    dl5: "29",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "125",
    Name: "Salice Salentino",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "6",
    dl2: "12",
    dl3: "18",
    dl5: "29",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "126",
    Name: "Chianti Capricorno",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "6",
    dl2: "12",
    dl3: "18",
    dl5: "27",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "127",
    Name: "Montepulciano",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "5",
    dl2: "10",
    dl3: "15",
    dl5: "25",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "128",
    Name: "Merlot Capricorno",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "4.9",
    dl2: "9.8",
    dl3: "14.5",
    dl5: "24",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "5500",
    Name: "VINI BIANCHI APERTI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "129",
    Name: "Pino Grigio Venezie",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "5.5",
    dl2: "11",
    dl3: "16.5",
    dl5: "27",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "130",
    Name: "Chardonnay Vin de France",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "5.5",
    dl2: "11",
    dl3: "16.5",
    dl5: "27",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "131",
    Name: "Fechy",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "5.5",
    dl2: "11",
    dl3: "16.5",
    dl5: "25",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "132",
    Name: "Epesse Swiss",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "6",
    dl2: "12",
    dl3: "18",
    dl5: "29",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "133",
    Name: "Sonup South Africa",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "5",
    dl2: "10",
    dl3: "15",
    dl5: "25",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "134",
    Name: "La Cote",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "5",
    dl2: "10",
    dl3: "15",
    dl5: "25",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "5500",
    Name: "VINI ROSATI APERTI",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "135",
    Name: "Garrigou Costes de Provence",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "6",
    dl2: "12",
    dl3: "18",
    dl5: "27",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "136",
    Name: "Rosato Castelli",
    Viti: "",
    Descriptione: "",
    Price: "",
    dl1: "5.5",
    dl2: "11",
    dl3: "16.5",
    dl5: "25",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "137",
    Name: "Prosecco di Valpolicella",
    Viti: "",
    Descriptione: "pro Glas,Flasche",
    Price: "9.50 / 65.00",
 

    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
  {
    categoryid: "9",
    Category: "VINO",
    id: "138",
    Name: "Cupli",
    Viti: "",
    Descriptione: "pro Glas",
    Price: "14.50",
    dl1: "",
    dl2: "",
    dl3: "",
    dl5: "",
  },
];
module.exports = products;
