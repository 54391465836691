<template>
  <div>
    <v-app-bar
      style="
        height: 70px;
        background: rgb(195 182 157);
        color: rgb(195 182 157);
      "
      class="fixed-bar"
    >
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->

      <!-- <v-toolbar-title>My files</v-toolbar-title> -->
      <!-- <a href="/res/"> -->
      <v-btn @click="$router.go(-1)" icon style="height: 60px; margin-top: 5px">
        <v-icon style="">mdi-arrow-left-thin</v-icon>
      </v-btn>
      <v-btn
        @click="$router.push({ path: '/italia2000/' })"
        @click.native="scrollToTop()"
        icon
        style="height: 60px; margin-top: 5px"
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <div style="text-align: center; margin-inline: auto">
        <img
          src="./../../assets/img/logo.jpg"
          alt=""
          srcset=""
          width="130px"
          style="margin-right: 70px; margin-top: 15px"
        />
      </div>
      <!-- </a> -->
      <!-- <v-spacer></v-spacer> -->

      <!-- <v-btn icon>
        <v-icon>mdi-filter</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-app-bar>
    <!-- <v-tabs
      align-with-title
      color="white accent-4"
      white
      prominent
      class="fixed-tabs"
      style="height: 80px; padding-top: 20px; background: white"
    >
      <v-tabs-slider color="white"></v-tabs-slider>

      <span
        style="height: 50px; text-align: center"
        v-for="card in category"
        :key="card.name"
      >
        <v-tab
          @click="$router.push({ path: `/${card.id}` })"
          class="active-class v-tab v-tab--active"
          style="
            height: 40px;
            background: rgb(233, 215, 194);
            border-radius: 20px;
          "
          v-if="card.id == $route.params.category"
        >
          {{ card.name }}
        </v-tab>
        <v-tab
          @click="$router.push({ path: `/${card.id}` })"
          v-else
          style="color: black; height: 40px"
        >
          {{ card.name }}
        </v-tab>
      </span>
    </v-tabs> -->
    <div class="fixed-tabs">
      <div
        style="
          /* background-color: #08c708; */
          overflow-x: scroll;
          overflow-y: hidden;
          white-space: nowrap;
          position: relative;
          width: 100%;
          height: 100%;
          padding-block: 20px;
        "
      >
        <div
          v-for="card in category"
          :key="card.name"
          style="display: inline-block"
        >
          <button
            @click="$router.push(`/italia2000/${card.id}`), myFunction(card.id)"
          >
            <div
              v-if="card.id == $route.params.category"
              :id="card.id"
              style="
                min-width: fit-content;
                border-radius: 20px;
                padding: 1.6vh;
                margin: 0.9vw;
                font-size: 2vh;
                font-weight: 600;
                background: rgb(195 182 157);
                opacity: 1;
                /* border: 1px solid; */
                width: max-content;
                display: inline-block;
              "
            >
              <a>
                <h4 style="display: inline; color: white">{{ card.name }}</h4>
              </a>
            </div>

            <div
              v-else
              :id="card.id"
              style="
                min-width: fit-content;
                border-radius: 20px;
                padding: 1.6vh;
                margin: 0.9vw;
                font-size: 2vh;
                font-weight: 600;
                color: rgb(195 182 157);
                opacity: 1;
                border: 1px solid;
                width: max-content;
                display: inline-block;
              "
            >
              <h4 style="display: inline; color: black">{{ card.name }}</h4>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div style="margin-top: 10px">
      <div v-for="item in product" :key="item.id">
        <!-- Drinks -->
        <v-col
          class="shrink"
          v-if="
            item.categoryid == $route.params.category &&
            $route.params.category == 9
          "
        >
          <div
            v-if="item.id == 5500"
            style="font-family: Georgia, 'Times New Roman', Times, serif"
          >
            <div style="text-align: center" height="150px">
              <hr style="margin-top: 20px; margin-bottom: 20px" />
              <h3 style="display: inline; color: black">
                {{ item.Name }}
              </h3>
              <hr style="margin-top: 20px; margin-bottom: 20px" />
            </div>
          </div>

          <v-card
            v-else
            style="
              background-color: white;
              box-shadow: rgb(195 182 157) 0px 5px 15px;
              border-radius: 10px;
              margin-bottom: 20px;
            "
          >
            <v-list-item three-line>
              <v-list-item-content style="color: black">
                <div class="text-overline mt-2">
                  <div
                    style="
                      font-family: Georgia, 'Times New Roman', Times, serif;
                    "
                  >
                    <div style="display: inline; te" height="150px ">
                      <h3 style="display: inline; color: red">
                        {{ item.Name }}
                      </h3>
                    </div>
                    <div
                      style="float: right; color: green; display: inline"
                      v-if="item.dl1 != ''"
                    >
                      <div style="text-align: center">
                        <div style="display: inline-block">
                          <h3 style="text-align: center">1Dl</h3>

                          <h3>CHF {{ item.dl1 }}</h3>
                        </div>
                        <div style="display: inline-block">
                          <h3 style="text-align: center">/</h3>

                          <h3>/</h3>
                        </div>
                        <div style="display: inline-block">
                          <h3 style="text-align: center">2Dl</h3>
                          <h3>
                            {{ item.dl2 }}
                          </h3>
                        </div>
                        <div style="display: inline-block">
                          <h3 style="text-align: center">/</h3>

                          <h3>/</h3>
                        </div>
                        <div style="display: inline-block">
                          <h3 style="text-align: center">3Dl</h3>
                          <h3>
                            {{ item.dl3 }}
                          </h3>
                        </div>
                        <div style="display: inline-block">
                          <h3 style="text-align: center">/</h3>

                          <h3>/</h3>
                        </div>
                        <div style="display: inline-block">
                          <h3 style="text-align: center">5Dl</h3>
                          <h3>
                            {{ item.dl5 }}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <h2 style="float: right; color: green" v-else>
                      CHF {{ item.Price }}
                    </h2>
                  </div>

                  <p style="font-family: Georgia">
                    {{ item.Descriptione }}
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <!-- Products -->

        <v-col
          class="shrink"
          v-if="
            item.categoryid == $route.params.category &&
            $route.params.category != 9
          "
        >
          <div
            v-if="item.id == 5500"
            style="font-family: Georgia, 'Times New Roman', Times, serif"
          >
            <div style="text-align: center" height="150px">
              <hr style="margin-top: 20px; margin-bottom: 20px" />
              <h3 style="display: inline; color: black">
                {{ item.Name }}
              </h3>
              <hr style="margin-top: 20px; margin-bottom: 20px" />
            </div>
          </div>

          <v-card
            v-else
            style="
              background-color: white;
              box-shadow: rgb(195 182 157) 0px 5px 15px;
              border-radius: 10px;
              margin-bottom: 20px;
            "
          >
            <v-list-item three-line>
              <v-list-item-content style="color: black">
                <div class="text-overline mt-2">
                  <div
                    style="
                      font-family: Georgia, 'Times New Roman', Times, serif;
                    "
                  >
                    <div style="display: inline" height="150px">
                      <h3 style="display: inline; color: red">
                        {{ item.Name }}
                      </h3>
                    </div>
                    <h2
                      style="float: right; color: green"
                      v-if="item.Priceh != ''"
                    >
                      CHF {{ item.Price }} / {{ item.Priceh }}
                    </h2>
                    <h2 style="float: right; color: green" v-else>
                      CHF {{ item.Price }}
                    </h2>
                  </div>

                  <p style="font-family: Georgia">
                    {{ item.Descriptione }}
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import PRODUCT from "../../data/menu/products.js";
import CATEGORY from "../../data/menu/categories.js";

export default {
  data() {
    return {
      product: PRODUCT,
      category: CATEGORY,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    myFunction(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
        // block: "center",
        inline: "center",
      }); // console.log(element.id);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0em;
  z-index: 2;
}
.fixed-tabs {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 4em;
  z-index: 2;
  background: white;
}
.element::-webkit-scrollbar {
  display: none;
}
#outer_wrapper {
  width: 100%;
}
</style>