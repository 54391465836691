<template>
  <div style="font-family: Georgia, 'Times New Roman', Times, serif">
    <v-app-bar
      style="
        height: 70px;
        margin-bottom: 50px;
        background: rgb(195 182 157)
        color:rgb(195 182 157);
      "
      dark
      class="fixed-bar"
    >
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->

      <!-- <v-toolbar-title>My files</v-toolbar-title> -->
      <!-- <a href="/res/"> -->

      <v-btn
        @click="$router.push({ path: '/italia2000/' })"
        @click.native="scrollToTop()"
        icon
        style="height: 60px; margin-top: 10px"
      >
        <v-icon color="rgba(0, 0, 0, 0.54)">mdi-home</v-icon>
      </v-btn>
      <!-- </a> -->
      <!-- <v-spacer></v-spacer> -->
      <div style="text-align: center; margin-inline: auto">
        <img
          src="./../../assets/img/logo.jpg"
          alt=""
          srcset=""
          width="130px"
          style="margin-right: 35px; margin-top: 15px"
        />
      </div>
      <!-- <v-btn icon>
        <v-icon>mdi-filter</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-app-bar>

    <!-- <v-navigation-drawer v-model="drawer" absolute bottom temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title>Foo</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Bar</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Fizz</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Buzz</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->
    <v-col>
      <v-row style="">
        <v-col
          style="margin-bottom: 40px"
          v-for="card in category"
          :key="card.name"
          :cols="12"
        >
          <v-card
            class="mx-5 rounded-xl"
            @click="$router.push(`/italia2000/${card.id}`)"
            @click.native="myFunction(card.id)"
            fab
            small
            style="box-shadow: rgb(233 215 194) 0px 5px 15px"
          >
            <v-img
              :src="card.image"
              class="white--text align-end"
              height="200px"
            ></v-img>
            <v-card-actions style="display: block">
              <div style="text-align: center">
                <h1>{{ card.name }}</h1>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import primipiati from "./../../assets/img/restourant/1.jpg";
import secondipiati from "./../../assets/img/restourant/2.jpg";
import spagettitalaiatele from "./../../assets/img/restourant/3.jpg";
import pasta from "./../../assets/img/restourant/4.jpg";
import pizza from "./../../assets/img/restourant/5.jpg";
import carne from "./../../assets/img/restourant/6.jpg";
import pesce from "./../../assets/img/restourant/7.jpg";
import desert from "./../../assets/img/restourant/8.jpg";
import vine from "./../../assets/img/restourant/9.jpg";
import drinks from "./../../assets/img/restourant/10.jpg";


export default {
  data: () => ({
    category: [
      { id: "1", name: "PRIMI PIATTA", image: primipiati },
      { id: "2", name: "SECONDI PIATTI", image: secondipiati },
      { id: "3", name: "SPHAGETTI O TAGLIATELLE", image: spagettitalaiatele },
      { id: "4", name: "PASTA ASCIUTTA E PASTA AL FORNO", image: pasta },
      { id: "5", name: "PIZZE", image: pizza },
      { id: "6", name: "SPECIALITA DI CARNE", image: carne },
      { id: "7", name: "SPECIALITA DI PESCE", image: pesce },
      { id: "8", name: "DESSERT", image: desert },
      { id: "9", name: "VINO", image: vine }, 
      { id: "10", name: "DRINKS", image: drinks },
    ],
  }),
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    myFunction(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
        // block: "center",
        inline: "center",
      });
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0em;
  z-index: 2;
}
</style>

