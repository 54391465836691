const categories = [
  { id: "1", name: "PRIMI PIATTA" },
  { id: "2", name: "SECONDI PIATTI" },
  { id: "3", name: "SPHAGETTI O TAGLIATELLE" },
  { id: "4", name: "PASTA ASCIUTTA E PASTA AL FORNO" },
  { id: "5", name: "PIZZE" },
  { id: "6", name: "SPECIALITA DI CARNE" },
  { id: "7", name: "SPECIALITA DI PESCE" },
  { id: "8", name: "DESSERT" },
  { id: "9", name: "VINO" },
  { id: "10", name: "DRINK" },
];

module.exports = categories;
